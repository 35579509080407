<template>
    <div class="main-layout">
        <div class="lotto-logo"><a href="https://lotto.nederlandseloterij.nl/" target="_blank"><img src="/static/img/logos/lotto.png" /></a></div>

        <div class="content">
            <slot></slot>
        </div>

        <footer>
            <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <p class="disclaimer small">Voor het organiseren van Lotto is aan Lotto bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        methods: {
            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            },

            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + '/static/pdf/actievoorwaarden.pdf' );
            }
        }
    };
</script>
