import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        // {
        //     path: '/',
        //     name: 'Landing',
        //     component: () =>
        //     {
        //         return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
        //     }
        // },
        {
            path: '/',
            name: 'KiesJeCadeau',
            component: () =>
            {
                return import( /* webpackChunkName: "kiesJeCadeau" */ '@/app/pages/kiesJeCadeau' );
            }
        },
        {
            path: '/loten',
            name: 'Tickets',
            component: () =>
            {
                return import( /* webpackChunkName: "tickets" */ '@/app/pages/Tickets' );
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },
        {
            path: '/overzicht',
            name: 'Overview',
            beforeEnter: ( to, from, next ) =>
            {
                if( !store.getters[ 'entry/get' ]( 'tickets' ) )
                {
                    next( '/loten' );
                }

                next();
            },
            component: () =>
            {
                return import( /* webpackChunkName: "overview" */ '@/app/pages/Overview' );
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },
        {
            path: '/bedankt',
            name: 'Bedankt',
            beforeEnter: ( to, from, next ) =>
            {
                if( !store.getters[ 'entry/get' ]( 'id' ) )
                {
                    next( '/' );
                }
                next();
            },
            component: () =>
            {
                return import( /* webpackChunkName: "bedankt" */ '@/app/pages/bedankt' );
            }
        },
        {
            path: '/form',
            name: 'form',
            component: () =>
            {
                return import( /* webpackChunkName: "form" */ '@/app/pages/form' );
            },
            children: [
                {
                    path: '1',
                    name: 'Form1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "form1" */ '@/app/pages/form/Form1' );
                    }
                },
                {
                    path: '2',
                    name: 'Form2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "form2" */ '@/app/pages/form/Form2' );
                    }
                },
                {
                    path: '3',
                    name: 'Form3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: "form3" */ '@/app/pages/form/Form3' );
                    }
                }
            ]
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
